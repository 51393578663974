
import { defineComponent } from "vue";
import Button from "../components/Button-component.vue";
import PlanetIllustration from "../illustrations/Platnet-illustration.vue";
import OrbitIllustration from "../illustrations/Orbit-iIlustration.vue";

export default defineComponent({
  name: "HelloWorld",
  components: {
    Button,
    PlanetIllustration,
  },
});
