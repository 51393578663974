
import { defineComponent } from "vue";
import Button from "./Button.vue";
import PlanetIlustration from "./PlatnetIlustration.vue";
import OrbitIlustration from "./OrbitIlustration.vue";
import TechStackIllustration from "../illustrations/TechStack-illustration.vue";

export default defineComponent({
  name: "TechStack",
  components: { TechStackIllustration },
});
