<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 530.63 540.81"
  >
    <defs></defs>
    <path
      class="cls-1"
      d="M1036,135.44"
      transform="translate(-287.02 -166.99)"
    />
    <g id="Planet">
      <path
        class="cls-2"
        d="M337.55,405.5a31.84,31.84,0,1,0-31.84-31.84A31.84,31.84,0,0,0,337.55,405.5Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-3"
        d="M331,355c1.68,0,3-.91,3-2s-1.35-2-3-2-3,.91-3,2S329.31,355,331,355Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-3"
        d="M361.31,381.24c2.24,0,4.05-2.49,4.05-5.56s-1.81-5.56-4.05-5.56-4,2.49-4,5.56S359.08,381.24,361.31,381.24Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-3"
        d="M330.48,395.39a3.09,3.09,0,1,0-2.53-3A2.81,2.81,0,0,0,330.48,395.39Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-3"
        d="M338.07,377.19a3.58,3.58,0,1,0-3-3.53A3.31,3.31,0,0,0,338.07,377.19Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-4"
        d="M663.69,510.44c0,109-84.29,197.24-188.28,197.24S287.14,619.39,287.14,510.44c0-84.45,57.25-160.24,127.28-186.68,30.57-11.54,55.76-10.79,61-10.58C559.31,316.64,663.69,395.92,663.69,510.44Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-5"
        d="M299.3,457.51c-3.4,6.29,54.08,20.72,59.64,28.3,3,4.1,69.47-23.61,77.83,20.21,17.69,92.49,3.68,79,36.89,111.7,14.15,7.07,48.84,34.44,68.74,29.31,54.58-14.15,1.31-15,18.19-109.16,0,0,40.81-46.55,58.13-41.95,10.25,2.72,19.75,49.89,29.31,16.17-8.21-76.51-79.18-129.38-181.94-124.33-51.55,23.25-82.88,36.39-90,36.39-78.39,15.16,24.76-61.8,30.33-61.66C390.08,316.41,286.38,467.22,299.3,457.51Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-6"
        d="M604,466.67c3,10.61-9.28,6.57-20.72,6.57s-20.72-2.94-20.72-6.57,2-12.63,20.72-6.57C594.11,463.63,602.94,463.18,604,466.67Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-7"
        d="M509.89,452.5c3,10.61-9.28,6.57-20.72,6.57s-20.72-2.94-20.72-6.57,6.57-30.83,25.27-24.77C504.6,431.26,508.88,449,509.89,452.5Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-7"
        d="M543.25,598.05c3,10.61-9.28,6.57-20.73,6.57s-20.72-2.94-20.72-6.57,6.57-30.83,25.27-24.76C538,576.81,542.23,594.56,543.25,598.05Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-8"
        d="M554.42,555.62c3,10.61-9.28,6.57-20.72,6.57S513,559.25,513,555.62s2-12.64,20.72-6.57C544.58,552.58,553.41,552.13,554.42,555.62Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-9"
        d="M500.79,444.92c3,10.61,3.86,7.07-7.58,7.07s-20.72-2.94-20.72-6.57,3.54-14.65,22.24-8.59C505.61,440.36,499.78,441.43,500.79,444.92Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-9"
        d="M544.31,519.74c3,10.61,3.86,7.07-7.58,7.07S516,523.87,516,520.24s3.54-14.66,22.24-8.59C549.13,515.18,543.3,516.25,544.31,519.74Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-9"
        d="M491.59,531.36c1.73,7.07,2.2,4.72-4.3,4.72s-11.77-2-11.77-4.39,2-9.77,12.64-5.73C494.33,528.32,491,529,491.59,531.36Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-10"
        d="M497.15,444.21c1.88,6.6,2.4,4.4-4.71,4.4s-12.87-1.83-12.87-4.05,2.19-9.09,13.81-5.33C500.15,441.38,496.54,442.05,497.15,444.21Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-10"
        d="M575.5,593.77c1.88,6.6,2.39,4.4-4.71,4.4s-12.88-1.83-12.88-4,2.2-9.1,13.82-5.34C578.49,590.94,574.88,591.61,575.5,593.77Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-10"
        d="M395.07,437.13c1.88,6.6,2.39,4.4-4.72,4.4s-12.87-1.83-12.87-4,2.19-9.1,13.81-5.34C398.06,434.3,394.45,435,395.07,437.13Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-11"
        d="M427.09,341.47c-6.06,3-3,7.78-3,7.81s11.12,5.42,32.34,4.21,24.26-9.62,24.26-9.62l4,1.2s25.27,3.61,26.28,0-3.88-7.41-3.88-7.41c-7.24-2.19-43.63-4.61-79,2.6a1.9,1.9,0,0,1-1,1.21Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-12"
        d="M440.15,696.85c-6.07-4-3-10.48-3-10.52s11.12-7.29,32.35-5.67,24.26,13,24.26,13l4-1.63S523,687.14,524,692s-3.88,10-3.88,10c-7.24,3-43.63,6.21-79-3.5A2.59,2.59,0,0,0,440.15,696.85Z"
        transform="translate(-287.02 -166.99)"
      />
      <g class="cls-13">
        <path
          class="cls-14"
          d="M488,414.51c1.25-2.18,2.81-2.8,4.52-1.87s1.56-.31,3.58-.78,2.66-.77,3.59.32.94,1.87,2.18,2.18.78-.78,2,.63,2.18,2,2,3a2.48,2.48,0,0,1-.73,1.6,2.51,2.51,0,0,1-1.61.73,23.1,23.1,0,0,1-5.31-1.09,5.65,5.65,0,0,0-4.2.15c-1.56.63-1.4,1.57-3.12.63s-.31-.47-1.87-1.24-2.8-.48-3-2S488,414.51,488,414.51Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M495.38,413c1.25-2.19,2.82-2.8,4.52-1.87s1.57-.32,3.59-.78,2.65-.78,3.59.31.93,1.87,2.18,2.18.78-.77,2,.63,2.18,2,2,3a2.57,2.57,0,0,1-.74,1.61,2.51,2.51,0,0,1-1.61.73,23.44,23.44,0,0,1-5.3-1.09,5.6,5.6,0,0,0-4.2.15c-1.57.62-1.41,1.56-3.12.62s-.32-.46-1.87-1.24-2.81-.47-3-2S495.38,413,495.38,413Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M511.72,418c1.25-2.18,2.81-2.8,4.53-1.87s1.55-.31,3.58-.78,2.64-.78,3.58.31.94,1.87,2.19,2.19.78-.78,2,.61,2.18,2,2,3a2.57,2.57,0,0,1-.72,1.61,2.53,2.53,0,0,1-1.61.73,23.21,23.21,0,0,1-5.29-1.07,5.63,5.63,0,0,0-4.21.15c-1.55.63-1.4,1.56-3.12.63s-.3-.47-1.87-1.25-2.8-.47-3-2S511.72,418,511.72,418Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M539.94,408.58c1.24-2.18,2.81-2.81,4.52-1.87s1.56-.31,3.59-.79,2.65-.78,3.58.31.94,1.87,2.19,2.19.78-.78,2,.62,2.18,2,2,3a2.51,2.51,0,0,1-2.34,2.33,22.8,22.8,0,0,1-5.3-1.09,5.55,5.55,0,0,0-4.2.16c-1.57.62-1.41,1.56-3.13.62s-.31-.47-1.87-1.25-2.81-.46-3-2S539.94,408.58,539.94,408.58Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M569.15,418c1.24-2.18,2.8-2.8,4.52-1.87s1.56-.31,3.59-.78,2.65-.78,3.58.31.94,1.87,2.18,2.19.78-.78,2,.61,2.18,2,2,3a2.49,2.49,0,0,1-2.33,2.34,22.91,22.91,0,0,1-5.3-1.09,5.63,5.63,0,0,0-4.21.15c-1.56.63-1.41,1.56-3.12.63s-.31-.47-1.87-1.25-2.81-.47-3-2S569.15,418,569.15,418Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M581,414c1.25-2.19,2.81-2.8,4.52-1.87s1.56-.32,3.59-.78,2.64-.78,3.58.31.93,1.87,2.19,2.18.77-.77,2,.63,2.18,2,2,3a2.51,2.51,0,0,1-2.35,2.34,23.25,23.25,0,0,1-5.29-1.09,5.63,5.63,0,0,0-4.21.15c-1.56.62-1.4,1.57-3.12.62s-.31-.46-1.87-1.24-2.8-.47-3-2S581,414,581,414Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M594.9,418.47c1.24-2.18,2.8-2.8,4.52-1.87s1.55-.31,3.58-.77,2.65-.78,3.58.31.94,1.87,2.19,2.18.77-.78,2,.62,2.18,2,2,3a2.51,2.51,0,0,1-.73,1.61,2.47,2.47,0,0,1-1.61.74,23.46,23.46,0,0,1-5.3-1.1,5.63,5.63,0,0,0-4.21.16c-1.56.62-1.4,1.55-3.11.62s-.32-.46-1.87-1.24-2.81-.48-3-2S594.9,418.47,594.9,418.47Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M590.44,422.93c1.24-2.18,2.8-2.81,4.52-1.87s1.55-.31,3.59-.78,2.64-.78,3.57.32.94,1.87,2.19,2.18.78-.79,2,.62,2.18,2,2,3A2.51,2.51,0,0,1,606,428.7a22.83,22.83,0,0,1-5.29-1.09,5.61,5.61,0,0,0-4.22.15c-1.55.63-1.4,1.56-3.11.63s-.31-.47-1.87-1.25-2.81-.47-3-2S590.44,422.93,590.44,422.93Z"
          transform="translate(-287.02 -166.99)"
        />
      </g>
      <g class="cls-13">
        <path
          class="cls-14"
          d="M534.78,568.17c.75-2.18,1.69-2.8,2.72-1.87s.94-.31,2.16-.78,1.6-.77,2.15.32.57,1.87,1.32,2.18.47-.78,1.22.63,1.31,2,1.22,3-.46,2.33-1.4,2.33a9.14,9.14,0,0,1-3.2-1.09,2.15,2.15,0,0,0-1.29-.33,2.24,2.24,0,0,0-1.24.48c-.94.63-.85,1.57-1.88.63s-.19-.46-1.12-1.24-1.7-.48-1.79-2a2.34,2.34,0,0,1,1.13-2.2Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M539.26,566.69c.75-2.19,1.68-2.8,2.73-1.87s.93-.32,2.15-.78,1.6-.78,2.16.31.57,1.87,1.31,2.19.48-.78,1.23.62,1.31,2,1.22,3-.47,2.34-1.41,2.34a9.49,9.49,0,0,1-3.2-1.09,2.08,2.08,0,0,0-1.29-.33,2.13,2.13,0,0,0-1.24.48c-.93.63-.84,1.56-1.87.63s-.2-.47-1.14-1.25-1.68-.47-1.77-2a2.21,2.21,0,0,1,.24-1.26A2.25,2.25,0,0,1,539.26,566.69Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M549.09,571.64c.75-2.18,1.69-2.8,2.72-1.87s.94-.31,2.16-.78,1.6-.78,2.17.31.56,1.87,1.31,2.19.46-.78,1.22.61,1.32,2,1.22,3-.47,2.34-1.41,2.34a9.29,9.29,0,0,1-3.19-1.09A2.2,2.2,0,0,0,554,576a2.17,2.17,0,0,0-1.24.48c-.94.63-.84,1.56-1.88.63s-.18-.47-1.12-1.24-1.69-.48-1.79-2a2.31,2.31,0,0,1,.25-1.26A2.25,2.25,0,0,1,549.09,571.64Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M566.09,562.24c.75-2.18,1.69-2.81,2.72-1.87s.94-.31,2.16-.79,1.6-.78,2.17.31.56,1.87,1.31,2.19.47-.78,1.22.62,1.32,2,1.22,3-.47,2.33-1.41,2.33a9.29,9.29,0,0,1-3.19-1.09,2.2,2.2,0,0,0-1.3-.33,2.18,2.18,0,0,0-1.24.49c-.94.62-.84,1.56-1.87.62s-.19-.47-1.13-1.25-1.69-.46-1.79-2a2.31,2.31,0,0,1,.25-1.26A2.36,2.36,0,0,1,566.09,562.24Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M583.73,571.64c.75-2.18,1.69-2.8,2.72-1.87s.94-.31,2.16-.78,1.6-.78,2.17.31.56,1.87,1.31,2.19.46-.78,1.22.61,1.32,2,1.23,3-.48,2.34-1.42,2.34a9.29,9.29,0,0,1-3.19-1.09,2.2,2.2,0,0,0-1.3-.33,2.17,2.17,0,0,0-1.24.48c-.94.63-.84,1.56-1.87.63s-.19-.47-1.13-1.24-1.69-.48-1.78-2a2.3,2.3,0,0,1,.24-1.26A2.25,2.25,0,0,1,583.73,571.64Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M590.81,567.68c.75-2.19,1.68-2.8,2.72-1.87s1-.32,2.16-.78,1.6-.78,2.16.31.57,1.87,1.32,2.18.47-.77,1.22.63,1.31,2,1.22,3-.47,2.34-1.41,2.34a9.56,9.56,0,0,1-3.2-1.09,2.15,2.15,0,0,0-1.29-.33,2.07,2.07,0,0,0-1.23.48c-1,.62-.85,1.57-1.88.62s-.2-.46-1.14-1.24-1.68-.47-1.78-2a2.31,2.31,0,0,1,.25-1.26A2.19,2.19,0,0,1,590.81,567.68Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M599.2,572.13c.75-2.18,1.69-2.8,2.73-1.87s.93-.31,2.16-.77,1.59-.78,2.15.31.57,1.87,1.31,2.18.48-.78,1.23.62,1.31,2,1.22,3-.46,2.35-1.4,2.35a9.29,9.29,0,0,1-3.2-1.1,2.25,2.25,0,0,0-1.29-.33,2.21,2.21,0,0,0-1.25.49c-.93.62-.84,1.55-1.88.62s-.19-.46-1.12-1.24-1.7-.48-1.79-2a2.38,2.38,0,0,1,.25-1.26A2.34,2.34,0,0,1,599.2,572.13Z"
          transform="translate(-287.02 -166.99)"
        />
        <path
          class="cls-14"
          d="M596.52,576.59c.75-2.18,1.69-2.81,2.72-1.87s.94-.31,2.16-.78,1.6-.77,2.16.32.56,1.87,1.32,2.18.46-.79,1.22.62,1.31,2,1.21,3-.46,2.34-1.4,2.34a9.07,9.07,0,0,1-3.2-1.09,2.14,2.14,0,0,0-2.53.15c-.94.63-.84,1.56-1.88.63s-.19-.47-1.13-1.25-1.68-.47-1.78-2a2.31,2.31,0,0,1,1.13-2.18Z"
          transform="translate(-287.02 -166.99)"
        />
      </g>
    </g>
    <g id="Astronaut">
      <path
        class="cls-15"
        d="M567.33,470.05s-8,6-11.93,12.6-.36,6.75,1.32,6.63c1.17-.09,4.65-.66,4.65-.66a64.83,64.83,0,0,0-1,7.62c0,1.29,2.65,5.31,5.31,4a9.12,9.12,0,0,0,3.64-3s-.79,7.3,8.29,9c2.61.48,3.65-4.31,5-4.31s2,3.31,4.65,4,4,.66,6-1.33c.66-.66.66-4.64.66-4.64l9.94-16.58S570,478.67,567.33,470.05Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-15"
        d="M784.82,341.41s4.42.84,4.31,2.32c-.14,2.09-1.65,2.32-5.63,4.31,2.65,1.33,3.65,1.66,3,3.65s-7.62.33-7.62.33l-6.3-22.21c4.2,1.17,13.92,2.08,15.91,3.31,1.24.76,2.54,1.92,2.65,2.65C791.54,338.38,785.94,340.93,784.82,341.41Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-16"
        d="M756,466.4S751.92,437.56,698.29,365c0,0,55.7-13.92,80.9-13.26-.66-2-6.63-21.88-6.63-21.88s-48.41-11.94-105.43-1.33c-2.65-15.25-12.6-86.2-55-90.18s-71,34.48-68,67.3c2.06,22.7.26,36.79,39.12,49.07,17.48,5.53,16.84,4.21,27.85,6.63,0,0-48.41,17.24-43.77,108.75,11.28,13.26,36.47,13.26,36.47,13.26l21.22-46,10.61-23-10,23.21,25.2,32.49L660.5,481s9,15.58,8.29,45.42,0,50.39,0,50.39-1.33,4.65,3.31,9.29,21.22,37.13,25.86,39.12,26.53,2.65,27.85-4-3-36.14-13.59-44.76c-6.63-17.9,19.23-57-.67-98.13l23.21,47.08s22.55,31.82,21.88,44.42,6.63,17.24,6.63,17.24,25.2,7.3,35.81,6.63c9-.56,31.17.67,7.3-23.87-11.94-4.64-27.19-13.26-27.19-13.26L756,466.4s0-29.06-58.35-101.45Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-17"
        d="M756,466.4S751.92,437.56,698.29,365c0,0,55.7-13.92,80.9-13.26-.66-2-6.63-21.88-6.63-21.88s-48.41-11.94-105.43-1.33c-2.65-15.25-12.6-86.2-55-90.18s-71,34.48-68,67.3c2.06,22.7.26,36.79,39.12,49.07,17.48,5.53,16.84,4.21,27.85,6.63,0,0-48.41,17.24-43.77,108.75,11.28,13.26,36.47,13.26,36.47,13.26l21.22-46,10.61-23-10,23.21,25.2,32.49L660.5,481s9,15.58,8.29,45.42,0,50.39,0,50.39-1.33,4.65,3.31,9.29,21.22,37.13,25.86,39.12,26.53,2.65,27.85-4-3-36.14-13.59-44.76c-6.63-17.9,19.23-57-.67-98.13l23.21,47.08s22.55,31.82,21.88,44.42,6.63,17.24,6.63,17.24,25.2,7.3,35.81,6.63c9-.56,31.17.67,7.3-23.87-11.94-4.64-27.19-13.26-27.19-13.26Zm0,0s0-29.06-58.35-101.45"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-18"
        d="M560,258A64.45,64.45,0,0,1,574,247.33c7.68-4.59,19-11.39,33.59-10.35,16.15,1.15,27,11.24,30.73,15,0,0,18.57,16,25.2,60.15-5.3,10-15.91,15.37-15.91,15.37s-60.34,36.75-102.78-22.72C544.49,297,544.64,274.2,560,258Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-19"
        d="M626.08,253.52a20.35,20.35,0,0,1,9.18,5.3,19.31,19.31,0,0,1,5.38,8.62,1,1,0,0,0,.48.55,1,1,0,0,0,.72.07,1,1,0,0,0,.58-.43,1,1,0,0,0,.14-.72,21.35,21.35,0,0,0-5.89-9.47,22.4,22.4,0,0,0-10.06-5.81c-1.24-.31-1.77,1.62-.53,1.92Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-20"
        d="M616.47,257.94a19.63,19.63,0,0,1,17.41,10.29,51,51,0,0,1,3.1,6.07c.52,1.17,2.23.16,1.72-1a38.56,38.56,0,0,0-6.2-10.3,20.3,20.3,0,0,0-9-5.87,28.06,28.06,0,0,0-7-1.2c-1.27-.06-1.27,1.93,0,2Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M611.76,361.3a83.33,83.33,0,0,0,38.46-12.6,75.69,75.69,0,0,0,7.29-5.3"
        transform="translate(-287.02 -166.99)"
      />
      <path
        d="M544.13,304.28l.66,2s21.88,38.46,57.69,37.79,63.65-19.89,63.65-22.54-2.65-10.61-2.65-10.61-8,23.21-55.7,27.85C568.66,339.42,548.77,308.26,544.13,304.28Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-22"
        d="M635.3,414.68c.22,0,7.29,41.78.66,35.81-6-7.3-10.61-13.26-10.61-13.26L636,414"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M711.89,576.14c-12.6,4.64-29.18,4-29.18,4"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M718.85,519.45A36.38,36.38,0,0,1,703,521.6q-.88-.11-1.71-.24"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M765.26,506.19a36,36,0,0,1-19.89,8.62,33.49,33.49,0,0,1-5.3,0"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-22"
        d="M588.55,379.87c-.33,4.31,23.87,4.1,39.79-2.65,21.88-9.29,20.41-9.38,27.18-19.9,1.91-2.95,4.73-10.52,2-13.26-2.65-1.32-7.29,13.26-47.08,17.91,0,0-10.19,5.55-15.91,11.27"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M753.33,441.87c-15.46,13.07-18.12,14.51-34.81,15.58"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M572.64,414.35c3.74,5.3,8.29,18.71,24.86,20.22a26.17,26.17,0,0,0,5.31,0"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-23"
        d="M668,349.86l-38.33,27.36,14.25,14.54,39-27.43Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-21"
        d="M712.35,324.5c10.15,8.37,6.83,10.94,6.83,10.94"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-24"
        d="M697,365c17.24-15.91,73.6-13.26,73.6-13.26Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-20"
        d="M611.33,247.51c.52-2-1.11-4.21-3.64-4.85s-5,.49-5.52,2.53,1.11,4.21,3.65,4.85S610.82,249.55,611.33,247.51Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-25"
        d="M668.45,579.79c-.1.66-1.1,2.06,0,9.28,0,0,18.57,34.48,24.54,40.45s31.83,2,31.83.66c0-.89.71-3.42.82-4.18a46.31,46.31,0,0,0,.5-5.76c-2.65,7.29-27.12,5.93-28.51,4C684.7,606,668.45,579.79,668.45,579.79Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-25"
        d="M759,579.46s.78,2.46.79,2.52c1.59,7.83,5.41,16.61,27.4,16.37,30.17-.33,30.17-.33,30.5-12.59,0,0-.67,8-23.87,7.29C758.63,589.07,759,579.46,759,579.46Z"
        transform="translate(-287.02 -166.99)"
      />
    </g>
    <g id="Phone">
      <path
        class="cls-26"
        d="M750.15,259v4.71L649,206v-4.7Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M700.58,171v4.71L649,206v-4.7Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M642.78,206.41V201.7a3,3,0,0,0,.56,1.49,3,3,0,0,0,1.24,1v4.72a3.1,3.1,0,0,1-1.24-1A3.06,3.06,0,0,1,642.78,206.41Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M801.75,228.62v4.7L700.57,175.67V171Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M809.26,228.24V233a2.87,2.87,0,0,1-.53,1.44,2.84,2.84,0,0,1-1.19,1v-4.7a3,3,0,0,0,1.19-1A2.94,2.94,0,0,0,809.26,228.24Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M701.9,172.28l101.16,57.65-51.59,30.38L650.3,202.65Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-26"
        d="M801.75,228.62v4.7L750.15,263.7V259Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M807.55,230.67v4.7l-53.1,31.27,0-4.7Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M746,262v4.71L644.58,208.91l0-4.71Z"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M697.61,168a9.36,9.36,0,0,1,8.5,0l101.35,57.74c2.37,1.35,2.41,3.55.09,4.92l-53.1,31.27a9.36,9.36,0,0,1-8.5,0L644.56,204.19c-2.36-1.34-2.4-3.55-.07-4.91Zm104.14,60.63L700.57,171,649,201.34,750.15,259l51.6-30.37"
        transform="translate(-287.02 -166.99)"
      />
      <path
        class="cls-27"
        d="M754.45,261.94v4.7a9.36,9.36,0,0,1-8.5,0v-4.7a9.36,9.36,0,0,0,8.5,0Z"
        transform="translate(-287.02 -166.99)"
      />
    </g>
    <path
      class="cls-16"
      d="M1033.07,125.24"
      transform="translate(-287.02 -166.99)"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlanetIlustration",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "../styles/animations.scss";



g#Planet {
  animation: float 5s linear infinite;
}
g#Astronaut {
  animation: float 5s ease-in-out infinite;
}

g#Phone {
  animation: float 5s ease-in-out infinite;
}

// Ilustration styles
.cls-1 {
  fill: #020202;
}
.cls-2 {
  fill: #e7d7bf;
  stroke: #fff8f8;
}
.cls-11,
.cls-12,
.cls-15,
.cls-17,
.cls-18,
.cls-2,
.cls-21,
.cls-24,
.cls-4 {
  stroke-miterlimit: 10;
}
.cls-11,
.cls-12,
.cls-2,
.cls-4 {
  stroke-width: 0.25px;
}
.cls-11,
.cls-12,
.cls-3 {
  fill: #fff8f8;
}
.cls-13,
.cls-14,
.cls-23,
.cls-3 {
  opacity: 0.5;
}
.cls-11,
.cls-12,
.cls-14,
.cls-22,
.cls-23,
.cls-24,
.cls-3 {
  isolation: isolate;
}
.cls-4 {
  fill: #68d8d8;
}
.cls-11,
.cls-12,
.cls-4 {
  stroke: #c4c4c4;
}
.cls-5 {
  fill: #78ff81;
}
.cls-6 {
  fill: #49fff0;
}
.cls-7 {
  fill: #7bff35;
}
.cls-8 {
  fill: #2efff9;
}
.cls-9 {
  fill: #51ff00;
}
.cls-10 {
  fill: #98ea00;
}
.cls-11 {
  opacity: 0.7;
}
.cls-12 {
  opacity: 0.9;
}
.cls-14,
.cls-20 {
  fill: #fff;
}
.cls-15 {
  fill: #c1c1c1;
}
.cls-15,
.cls-18,
.cls-21 {
  stroke: #000;
}
.cls-16 {
  fill: #d7b178;
}
.cls-17,
.cls-21 {
  fill: none;
}
.cls-17 {
  stroke: #070000;
}
.cls-18 {
  fill: #6abfce;
}
.cls-19 {
  fill: #f4f4f4;
}
.cls-22 {
  opacity: 0.13;
}
.cls-24 {
  fill: #070000;
  stroke: #e5e5e5;
  opacity: 0.3;
}
.cls-25 {
  fill: #878787;
}
.cls-26 {
  fill: #c6c6c6;
}
.cls-27 {
  fill: #515151;
}
</style>
