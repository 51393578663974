
import { defineComponent } from "vue";

export default defineComponent({
  name: "TechStackIlustration",
  data: () => {
    return {
      angularDuration: Math.random() * 3 + 3.5 + "s",
      cypressDuration: Math.random() * 3 + 3.5 + "s",
      typescriptDuration: Math.random() * 3 + 3.5 + "s",
      rxjsDuration: Math.random() * 3 + 3.5 + "s",
      sassDuration: Math.random() * 3 + 1.5 + "s",

      nodeDuration: Math.random() * 3 + 1.5 + "s",
      htmlDuration: Math.random() * 3 + 1.5 + "s",
      cssDuration: Math.random() * 3 + 1.5 + "s",

      webpackDuration: Math.random() * 3 + 1.5 + "s",
    };
  },
});
