
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    text: String,
  },
  methods: {
    navigate: (location: string) => {
      window.location.href = location;
    },
  },
});
