
import { defineComponent } from "vue";
import TechStack from "./sections/TechStack-section.vue";
import HeroBanner from "./sections/HeroBanner-section.vue";
import Experience from "./sections/Experience-section.vue";
import Contact from "./sections/Contact-section.vue";

export default defineComponent({
  name: "App",
  components: {
    HeroBanner,
    TechStack,
    Experience,
    Contact,
  },
});
